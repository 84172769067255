<template>
    <div>
        <!--<div class="search">
           <el-form :inline="true" :model="query" class="demo-form-inline"  size="small">
            <el-form-item label="授权类型:" class="confer-type">
              <el-select v-model="query.type[0]" placeholder="阅读授权">
                <el-option label="阅读授权" value="0"></el-option>
                <el-option label="请求授权" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" class="confer-type">
              <el-select v-model="query.state[0]" placeholder="所有">
                <el-option label="所有" value=""></el-option>
                <el-option label="待授权" value="0"></el-option>
                <el-option label="已通过" value="1"></el-option>
                <el-option label="已拒绝" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="日期:" class="date">
                <el-col :span="11">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="query.start"></el-date-picker>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="query.end"></el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button type="default" @click="doSearch">查询</el-button>
            </el-form-item>
          </el-form> 
      </div>-->
     <div class="files-table">
        <!-- <el-table size="small" :data="viewer.list" v-loading="loading" :show-header="false">
          <el-table-column
            width="10">
          </el-table-column>
          <el-table-column label="共享标题">
            <template slot-scope="scope">
              <div style="font-weight: bold;color:#009DD9;font-size:17px;cursor: pointer;" @click="goDetail(scope.row)"> <i class="el-icon-document" style="color:#ffaf1d;font-size:23px;margin-right:5px"></i>{{ scope.row.shareName }}</div>
                <ul class="share-share noFlex">
									<li class="table-share table-confer table-span">
										<span>申请人: {{scope.row.targetName}}</span>
										<span>申请时间: {{scope.row.createDate|date}}</span>
										<span>授权类型: {{scope.row.type|requesType}}</span>
									</li>
                  <li class="table-share table-iphone">联系电话:{{scope.row.targetValue}}</li>
                  <li v-if="scope.row.disposeDate" class="table-share table-span">
										<span>授权人:{{scope.row.userName}}</span>
										<span>授权时间:{{scope.row.disposeDate|date}}</span>
									</li>
                  <li class="table-share">申请原因: {{scope.row.description}}</li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column
              label="访问地址"
              width="200">
              <template slot-scope="scope" style="display:flex;">
                <p>
                  <span>状态: </span>
                  <span :style="scope.row.state|applyStateColor">  {{scope.row.state|applyState}}</span>
                </p>
              </template>
            </el-table-column>
          </el-table> -->
          <el-table
            v-if='viewer.list.length != 0'
            :data="viewer.list"
            size="small"
            v-model="loading"
            height="calc(100vh - 265px)"
            style="width: 100%">
            <el-table-column
              label="文件名称"
              width="200">
                <template slot-scope="scope">
                    <div class="record-first">
                        <i class="el-icon-document" style="color:#ffaf1d;font-size:23px;margin-right:5px"></i>
                        <span>{{ scope.row.shareName }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
              prop="targetName"
              label="申请人"
              align="center">
            </el-table-column>
            <el-table-column
              label="申请时间"
              align="center">
                <template slot-scope="scope">
                  {{scope.row.createDate | date}}
                </template>
            </el-table-column>
            <!-- <el-table-column
              prop="targetValue"
              label="联系电话"
              align="center">
            </el-table-column> -->
            <el-table-column
              prop="description"
              label="申请原因"
              show-overflow-tooltip
              align="center">
            </el-table-column>
            <el-table-column
              label="授权类型"
              align="center">
              <template slot-scope="scope">
                {{scope.row.type|requesType}}
              </template>
            </el-table-column>
            <el-table-column
              label="授权人"
              align="center">
                <template slot-scope="scope" v-if="scope.row.disposeDate">
                  {{scope.row.userName}}
                </template>
            </el-table-column>
            <el-table-column
              label="授权时间"
              align="center">
                <template slot-scope="scope" v-if="scope.row.disposeDate">
                  {{scope.row.disposeDate | date}}
                </template>
            </el-table-column>
            <el-table-column
              label="状态"
              align="center">
              <template slot-scope="scope">
                  <el-tag type="primary" size="small" v-if="scope.row.state==0">待授权</el-tag>
                  <el-tag type="success" size="small" v-else-if="scope.row.state==1">已通过</el-tag>
                  <el-tag type="info" size="small" v-else-if="scope.row.state==3">已取消</el-tag>
                  <el-tag type="danger" size="small" v-else>已拒绝</el-tag>
              </template>
            </el-table-column>
            <el-table-column
            label="操作"
            align="center"
            width="80">
              <template slot-scope="scope">
                  <el-button type="text" size="small" @click="goDetail(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>

        </div>
         <div class="no-data-box" v-if='viewer.list.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>
        <div class="pagination"  v-if='viewer.list.length != 0'>
          <el-pagination
            @current-change="pageChanged"
            layout="total,  prev, pager, next, jumper"
						:page-size="pages"
            :total="total">
          </el-pagination>
        </div>
        <RequestrecordDialog ref="requestRecordPages"></RequestrecordDialog>
    </div>
</template>
<script>
import RequestrecordDialog from '../liankong/components/RequestRecordDialog.vue'
export default {
    data(){
      return{
        viewerKey: "receiver",
        query: {
            state:[],
            start:'',
            end:'',
						type:[]
        },
        loading:false
      }
    },
    components: {
      RequestrecordDialog
    },
    computed: {
      viewer(){
        return this.$store.state.request.views[this.viewerKey]
      },
			total(){
				return this.$store.state.request.views.receiver.paging.total
			},
			pages(){
				return this.$store.state.request.views.receiver.query.pageSize
			}
    },
    created() {
      this.$store.commit('request/updateQuery', {
          vkey: this.viewerKey,
          query:this.query
        })
      this.loadList(true,false)
    },
    mounted(){
      this.$ebus.$on('doSearch', formRequestQuery=>{
        this.query.state = formRequestQuery.state;
        this.query.start = formRequestQuery.start;
        this.query.end = formRequestQuery.end;
        this.query.type = formRequestQuery.type;
        this.doSearch();
      })
    },
    methods: {
			pageChanged(num){
			this.doSearch(num,true)
		},
    /**
     * 加载列表
     */
      loadList(flag,noFlag) {
        this.loading = true
        this.$store.dispatch("request/loadReceiveByMe", {
          reset: flag ? true : false,
          noRest:noFlag ? true : false 
        }).then(res=>{
          if(res){
            this.loading = false
          }
        })
      },
      /**
       * 查询
       */
      doSearch(num,flag) {
        let query = {...this.query}
        if(query.state[0]==""){
          query.state=[]
        } 
        if(query.type[0] == ""){
          query.type = []
        }
        if(flag){
          query.pageNum = num
        }
        this.$store.commit('request/updateQuery', {
          vkey: this.viewerKey,
          query
        })
        this.loadList(false,false)
      },
      //详情页
      goDetail(data){
        this.$mapData.put('keepConferDetail',data)
        this.$refs.requestRecordPages.dialogVisible = true;
        this.$nextTick(()=>{
           this.$refs.requestRecordPages.onLoadPage()
        })
        // this.$router.replace({
        //   path:'/confer/detail'
        // })
      }
    },
    beforeDestroy() {
        this.$ebus.$off('doSearch');
    }
}
</script>
<style lang="less" scoped>
.search {
    height: 60px;
    display: flex;
    background: #F2F2F2;
    border-radius: 10px;
    .demo-form-inline {
        display: flex;
        height: 100%;
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
        align-items: center;
        .el-form-item {
          margin: 0 10px 0 0;
          /deep/ .el-form-item__content {
            .el-date-editor {
              width: 170px;
            }
            .line {
              text-align: center;
              padding-left: 3px;
              box-sizing: border-box;
            }
          }
        }
				.confer-type {
					width: 25%;
					/deep/ .el-form-item__content{
	          width: 60%;
					}
				}
        .date {
          width: 50%;
        }
    }
}
 .files-table{
     margin: 0;
    .table-search {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #c6cad636;
    }
    // .el-table{
    //   /deep/ .el-table__body-wrapper{
    //     .el-table__body{
    //       font-size: 16px;
    //       .el-table__row {
    //         td {
    //           .share-share {
    //             display: flex;
    //             list-style: none;
    //             margin: 10px 0 0;
    //             padding: 0px;
    //             box-sizing: border-box;
    //           }
    //           .noFlex {
    //             flex-direction: column;
    //             .table-share {
    //               padding: 2px 0;
    //               box-sizing: border-box;
    //             }
    //             .table-iphone{
    //               margin-left: 0;
    //             }
		// 						.table-span{
		// 							>span:not(:first-child){
		// 								margin-left: 10px;
		// 							}
		// 						}
    //           }
    //           .share-read {
    //             justify-content: space-around;
    //             margin: 0px;
    //           }
    //           .icon-color{
    //             padding: 5px;
    //             box-sizing: border-box;
    //             color: black;
    //             border: 1px solid #dddddd;
    //             border-radius: 15px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .file-column-name{
      display: flex;
      justify-content: space-between;
      margin-right: 50px;

      a{
        color: initial;
        text-decoration: none;
      }

      .btns{
        font-size: 16px;
        i{
          display: inline-block;
          padding: 5px;
          margin: 0 2px;
          cursor: pointer;
        }
      }
    
    }
    // /deep/ .el-table__row {
    //       // background: red;
    //         td:first-child {
    //           font-size: 28px;
    //           vertical-align: top !important;
    //           .icon-gaizhang{
    //              font-size: 28px;
    //              color:#ffca09;
    //           }
    //         }
    //         td:last-child .cell {
    //           margin-top: -60px;
    //           .el-button--medium {
    //               i {
    //                 font-size: 20px;
    //               }
    //           }
    //           // display: flex;
    //           // flex-direction: column;
    //           // align-content: center
    //         }
    //       }
      /deep/ .el-table {
        .record-first {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
            span {
                display: block;
                width: 150px; 
                height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 1;
                white-space: nowrap;
                // -webkit-box-orient: vertical;
            }
            .doc-pic {
                width: 28px;
                height: 28px;
                margin-bottom: 5px;
            }
        }
        .el-table__header-wrapper {
            .has-gutter {
                tr th{
                    border:none;
                    background: #F2F2F2;
                    color: #666;
                }
            }
        }
        .el-table__body-wrapper {
            tr td {
                border:none;
                padding: 12px 0;
            }
        }
        &::before {
            height: 0px;
        }
    }
    }
    .pagination{
      .el-pagination {
        text-align: left;
        margin-top: 20px;
      }
    }
</style>


